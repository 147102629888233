import axios from "axios";
import {getCookie} from "../cookie/cookie";

export function reloadSearch(searchId, projectId, primaryWord, volumeMin, country) {
    let burl = process.env.VUE_APP_API;
    console.log(burl);
    return axios({
        method: 'post',
        url: burl + '/api/project/search/add_search',
        data : {searchId, projectId, primaryWord, volumeMin, country},
        headers : {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + getCookie("token")
        }
    })
}

export function reloadUrlBasedSearch(searchId, projectId, url, country) {
    let burl = process.env.VUE_APP_API;
    return axios({
        method: 'POST',
        url: burl + '/api/project/search/add_url_based_search',
        data : {searchId, projectId, url, country},
        headers : {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + getCookie("token")
        }
    })
}
