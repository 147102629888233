<template>
  <vs-button v-if="!disabled"
      class="button--reload"
      icon
      color="success"
      @click.stop="reloadSearch(search._id)"
  >
    <i class='bx bx-sync'/>{{ text }}
  </vs-button>
  <vs-button v-else
             disabled
             class="button--reload"
             icon
             color="success"
  >
    <i class='bx bx-sync'/>{{ text }}
  </vs-button>
</template>

<script>
import {reloadSearch, reloadUrlBasedSearch} from "../utils/search/reload";

export default {
  name: "SearchButtonReload",
  props: {
    search: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    text:{
      type: String
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  methods: {
    async reloadSearch(searchId, searchName = '') {
      if (this.search.type === 'url_based'){
        await reloadUrlBasedSearch(searchId, this.project._id, this.search.url, this.search.params.country)
            .then(res => {
              this.reloadSuccess(res)
            })
            .catch(() => {
              this.notificationError(`Failed to reload <b>${searchName}</b>`)
            })
      } else {
        await reloadSearch(searchId, this.project._id, this.search.primaryWord, this.search.secondaryWord, this.search.location, this.search.params.volumeMin, this.search.params.country)
            .then(res => {
              this.reloadSuccess(res)
            })
            .catch(() => {
              this.notificationError(`Failed to reload <b>${searchName}</b>`)
            })
      }
    },
    reloadSuccess(res){
      this.$emit('reload-search')
      this.$router.push({
        name: 'Search',
        params: {nameProject: this.project.name, idSearch: res.data.search._id, reload: true}
      })
    }
  }

}
</script>

<style scoped>

</style>
