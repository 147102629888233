<template>
  <vs-dialog blur v-model="active" @close="handleClose">
    <template #header>
      <h4 class="not-margin" v-html="$t('search.create.title')"/>
    </template>

    <div>
      <vs-button-group>
        <vs-button :flat="!keywordSearch" icon @click="keywordSearch=true">
          <i class='bx bxs-keyboard' style="margin-right: 10px" /> {{$t('search.type.keyword').toUpperCase()}}
        </vs-button>
        <vs-button :flat="keywordSearch" icon @click="keywordSearch=false">
          <i class='bx bx-at' style="margin-right: 10px" /> {{$t('search.type.url_based').toUpperCase()}}
        </vs-button>
      </vs-button-group>
    </div>
    <br/>

    <!-- REGULAR SEARCH -->
    <div class="con-form" v-if="keywordSearch">
        <vs-tooltip color="primary" border-thick >
          <b-field
              :label="$tc('search.keyword', 2, {tooltip : '*'})"
              :message="errors.primaryWord ? $t('errors.primaryWord') : ''"
              :type="errors.primaryWord ? 'is-danger' : ''"
          >
            <b-taginput
                    v-model="primaryWord"
                    ellipsis
                    maxtags="5"
                    icon-pack="fas"
                    icon="tag"
                    type="is-primary"
                    close-type="is-danger"
                    :placeholder="$t('search.create.placeholder_keyword')"
                    aria-close-label="Delete this tag"
                    style="margin-bottom: 5px"
            >
            </b-taginput>
          </b-field>
          <template #tooltip>
            {{$t('search.create.tooltip_keyword')}}
          </template>
        </vs-tooltip>

      <vs-tooltip color="primary" border-thick >
        <b-field :label="$t('search.volume', {tooltip: '*'})">
          <vs-input
              v-model="volumeMin"
              type="number"
              min="1"
              style="margin-bottom: 5px"
          >
            <template v-if="errors.volumeMin" #message-danger>
              {{ $t('errors.volumeMin') }}
            </template>
          </vs-input>
        </b-field>
        <template #tooltip>
          {{$t('search.create.tooltip_volume_min')}}
        </template>
      </vs-tooltip>

      <vs-tooltip color="primary" border-thick >
        <b-field :label="$t('search.country', {tooltip : '*'})">
          <country-select @country-selected="handleCountryChange"/>
        </b-field>
        <template #tooltip>
          {{$t('search.create.tooltip_country')}}
        </template>
      </vs-tooltip>

      <div v-if="$cookies.get('new_user') !== 'true'">
        <vs-tooltip color="primary" border-thick>
          <vs-checkbox v-model="enriched" style="margin-top: 6px">
            {{$t('search.create.input_audit_competitor')}}
          </vs-checkbox>
          <template #tooltip>
            {{$t('search.create.tooltip_enriched')}}
          </template>
        </vs-tooltip>
      </div>
      <p style="font-style: italic;font-size:10px;" v-if="enriched" v-html="$t('search.create.alert_enriched')"/><br/>
    </div>

    <!-- URL BASED SEARCH -->
    <div class="con-form" v-else>
      <vs-tooltip color="primary" border-thick >
        <b-field :label="$t('search.domain')+'*'">
          <vs-input
              type="url"
              v-model="url"
              placeholder="www.semantik-seo.com"
          >
          <template #icon><i class='bx bx-link'/></template>
          <template v-if="errors.url" #message-danger>
            {{ $t('errors.url') }}
          </template>
          </vs-input>
        </b-field>
        <template #tooltip>
          {{$t('search.create.tooltip_domain')}}
        </template>
      </vs-tooltip>

      <vs-tooltip color="primary" border-thick >
        <b-field :label="$t('search.volume', {tooltip: '*'})">
          <vs-input
                  v-model="volumeMin"
                  type="number"
                  min="1"
                  style="margin-bottom: 5px"
          >
            <template v-if="errors.volumeMin" #message-danger>
              {{ $t('errors.volumeMin') }}
            </template>
          </vs-input>
        </b-field>
        <template #tooltip>
          {{$t('search.create.tooltip_volume_min')}}
        </template>
      </vs-tooltip>

      <vs-tooltip color="primary" border-thick >
        <b-field label="Country *">
          <country-select @country-selected="handleCountryChange"/>
        </b-field>
        <template #tooltip>
          {{$t('search.create.tooltip_country')}}
        </template>
      </vs-tooltip>

      <div v-if="$cookies.get('new_user') !== 'true'">
        <vs-tooltip color="primary" border-thick>
          <vs-checkbox v-model="enriched" style="margin-top: 6px">
            {{$t('search.create.input_audit_competitor')}}
          </vs-checkbox>
          <template #tooltip v-if="!enriched" >
            {{$t('search.create.tooltip_enriched')}}
            Perform a review of each competitor, getting Domain Authority, TrustFlow, Citation Flow, Tropical TrustFlow and Traffic.
          </template>
        </vs-tooltip>
      </div>


      <p style="font-style: italic;font-size:10px;" v-if="enriched" v-html="$t('search.create.alert_enriched')"></p>
      <br/>
    </div>

    <template #footer>
      <div class="footer-dialog" v-if="keywordSearch">
        <vs-button gradient block @click="sendSearch">{{$t('search.create.button')}}</vs-button>
      </div>
      <div class="footer-dialog" v-else>
        <vs-button gradient block @click="sendUrlBasedSearch">{{$t('search.create.button')}}</vs-button>
      </div>
    </template>
  </vs-dialog>

</template>

<script>
  import {createSearch, createUrlBasedSearch} from "@/utils/search/create";
  import CountrySelect from "./CountrySelect";

export default {
  name: "SearchDialogCreate",
  components:{
    CountrySelect,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    idProject: {
      type: String,
    },
    nameProject: {
      type: String,
    },
    searchParams : {type :Object}
  },
  data() {
    return {
      primaryWord: this.searchParams ? this.searchParams.primaryWord : [],
      volumeMin: this.searchParams && this.searchParams.params ? this.searchParams.params.volumeMin : 20,
      country: this.searchParams && this.searchParams.params ? this.searchParams.params.country : '',
      url: '',
      active: false,
      enriched: this.searchParams ? this.searchParams.enriched : false,
      keywordSearch: true,
      errors: {},
    }
  },
  watch: {
    isActive(value){
      this.active = value
    },
    searchParams(value){
      this.primaryWord = value.primaryWord
      this.volumeMin= value.params.volumeMin
      this.handleCountryChange(value.params.country)
      this.enriched= value.enriched
    }
  },
  created() {
    this.active = this.isActive
    if(this.searchParams){
      this.primaryWord= this.searchParams.primaryWord
      this.volumeMin= this.searchParams.params.volumeMin
      this.handleCountryChange(this.searchParams.params.country)
      this.enriched= this.searchParams.enriched
    }


  },

  methods: {
    handleCountryChange(country){
      this.country = country
    },
    handleClose() {
      this.$emit('handleDialogClose')
    },
    checkForm(urlBasedSearch = false) {
      this.errors = {}

      if (urlBasedSearch) {
        if (!this.url) {
          this.errors.url = true
        }
      } else {
        if (this.primaryWord.length === 0) {
          this.errors.primaryWord = true
        }
      }

      if (!this.country) {
        this.errors.country = true
      }

      if (this.volumeMin < 20) {
        this.errors.volumeMin = true
      }

    },
    sendSearch() {
      this.checkForm()

      if (Object.keys(this.errors).length === 0) {
        createSearch(this.idProject, this.primaryWord, this.volumeMin, this.country, this.enriched)
            .then(res => {
              this.notificationSuccess('Search created');
              this.$cookies.set('new_user', false);
              this.$router.push({
                name: 'Search',
                params: {nameProject: this.nameProject, idSearch: res.data.search._id, reload: true}
              })

            })
            .catch(() => {
              this.notificationError('An error occurred while creating the search')
            })
      }
    },
    sendUrlBasedSearch() {
      this.checkForm(true)

      if (Object.keys(this.errors).length === 0) {
        createUrlBasedSearch(this.idProject, this.url, this.volumeMin, this.country, this.enriched)
            .then(res => {
              this.notificationSuccess('Website keyword audit created');
              this.$router.push({
                name: 'Search',
                params: {nameProject: this.nameProject, idSearch: res.data.search._id, reload: true}
              })
            })
            .catch(() => {
              this.notificationError('An error occurred while creating the search')
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
