<template>
  <vs-select
      class="Select"
      filter
      :placeholder="$t('search.country')"
      v-model="country"
  >
    <template v-if="error" #message-danger>
      {{ $t('errors.volumeMin') }}
    </template>
    <vs-option value="af" label="Afghanistan"><country-flag rounded country='af'/> Afghanistan</vs-option>
    <vs-option value="al" label="Albania"><country-flag rounded country='al'/> Albania</vs-option>
    <vs-option value="dz" label="Algeria"><country-flag rounded country='dz'/> Algeria</vs-option>
    <vs-option value="ao" label="Angola"><country-flag rounded country='ao'/> Angola</vs-option>
    <vs-option value="ar" label="Argentina"><country-flag rounded country='ar'/> Argentina</vs-option>
    <vs-option value="am" label="Armenia"><country-flag rounded country='am'/> Armenia</vs-option>
    <vs-option value="au" label="Australia"><country-flag rounded country='au'/> Australia</vs-option>
    <vs-option value="at" label="Austria"><country-flag rounded country='at'/> Austria</vs-option>
    <vs-option value="az" label="Azerbaijan"><country-flag rounded country='az'/> Azerbaijan</vs-option>
    <vs-option value="bs" label="Bahamas"><country-flag rounded country='bs'/> Bahamas</vs-option>
    <vs-option value="bd" label="Bangladesh"><country-flag rounded country='bd'/> Bangladesh</vs-option>
    <vs-option value="by" label="Belarus>"><country-flag rounded country='by'/> Belarus</vs-option>
    <vs-option value="be" label="Belgium"><country-flag rounded country='be'/> Belgium</vs-option>
    <vs-option value="bz" label="Belize"><country-flag rounded country='bz'/> Belize</vs-option>
    <vs-option value="bo" label="Bolivia"><country-flag rounded country='bo'/> Bolivia</vs-option>
    <vs-option value="ba" label="Bosnia and Herzegovina"><country-flag rounded country='va'/> Bosnia and Herzegovina</vs-option>
    <vs-option value="bw" label="Botswana"><country-flag rounded country='bw'/> Botswana</vs-option>
    <vs-option value="br" label="Brazil"><country-flag rounded country='br'/> Brazil</vs-option>
    <vs-option value="bn" label="Brunei Darussalam"><country-flag rounded country='bn'/> Brunei Darussalam</vs-option>
    <vs-option value="bg" label="Bulgaria"><country-flag rounded country='bg'/> Bulgaria</vs-option>
    <vs-option value="kh" label="Cambodia"><country-flag rounded country='kh'/> Cambodia</vs-option>
    <vs-option value="cm" label="Cameroon"><country-flag rounded country='cm'/> Cameroon</vs-option>
    <vs-option value="ca" label="Canada"><country-flag rounded country='ca'/> Canada</vs-option>
    <vs-option value="cv" label="Cape Verde"><country-flag rounded country='cv'/> Cape Verde</vs-option>
    <vs-option value="cl" label="Chile"><country-flag rounded country='cl'/> Chile</vs-option>
    <vs-option value="co" label="Colombia"><country-flag rounded country='co'/> Colombia</vs-option>
    <vs-option value="cd" label="Congo"><country-flag rounded country='cd'/> Congo</vs-option>
    <vs-option value="cr" label="Costa Rica"><country-flag rounded country='cr'/> Costa Rica</vs-option>
    <vs-option value="hr" label="Croatia"><country-flag rounded country='hr'/> Croatia</vs-option>
    <vs-option value="cy" label="Cyprus"><country-flag rounded country='cy'/> Cyprus</vs-option>
    <vs-option value="cz" label="Czech Republic"><country-flag rounded country='cz'/> Czech Republic</vs-option>
    <vs-option value="dk" label="Denmark"><country-flag rounded country='dk'/> Denmark</vs-option>
    <vs-option value="do" label="Dominican Republic"><country-flag rounded country='do'/> Dominican Republic</vs-option>
    <vs-option value="ec" label="Ecuador"><country-flag rounded country='ec'/> Ecuador</vs-option>
    <vs-option value="eg" label="Egypt"><country-flag rounded country='eg'/> Egypt</vs-option>
    <vs-option value="sv" label="El Salvador"><country-flag rounded country='sv'/> El Salvador</vs-option>
    <vs-option value="ee" label="Estonia"><country-flag rounded country='ee'/> Estonia</vs-option>
    <vs-option value="fi" label="Finland"><country-flag rounded country='fi'/> Finland</vs-option>
    <vs-option value="fr" label="France"><country-flag rounded country='fr'/> France</vs-option>
    <vs-option value="ge" label="Georgia"><country-flag rounded country='ge'/> Georgia</vs-option>
    <vs-option value="de" label="Germany"><country-flag rounded country='de'/> Germany</vs-option>
    <vs-option value="gh" label="Ghana"><country-flag rounded country='gh'/> Ghana</vs-option>
    <vs-option value="gr" label="Greece"><country-flag rounded country='gr'/> Greece</vs-option>
    <vs-option value="gt" label="Guatemala"><country-flag rounded country='gt'/> Guatemala</vs-option>
    <vs-option value="gy" label="Guyana"><country-flag rounded country='gy'/> Guyana</vs-option>
    <vs-option value="ht" label="Haiti"><country-flag rounded country='ht'/> Haiti</vs-option>
    <vs-option value="hn" label="Honduras"><country-flag rounded country='hn'/> Honduras</vs-option>
    <vs-option value="hk" label="Hong Kong"><country-flag rounded country='hk'/> Hong Kong</vs-option>
    <vs-option value="hu" label="Hungary"><country-flag rounded country='hu'/> Hungary</vs-option>
    <vs-option value="is" label="Iceland"><country-flag rounded country='is'/> Iceland</vs-option>
    <vs-option value="in" label="India"><country-flag rounded country='in'/> India</vs-option>
    <vs-option value="id" label="Indonesia<"><country-flag rounded country='id'/> Indonesia</vs-option>
    <vs-option value="ie" label="Ireland"><country-flag rounded country='ie'/> Ireland</vs-option>
    <vs-option value="il" label="Israel"><country-flag rounded country='il'/> Israel</vs-option>
    <vs-option value="it" label="Italy"><country-flag rounded country='it'/> Italy</vs-option>
    <vs-option value="jm" label="Jamaica"><country-flag rounded country='jm'/> Jamaica</vs-option>
    <vs-option value="jp" label="Japan"><country-flag rounded country='jp'/> Japan</vs-option>
    <vs-option value="jo" label="Jordan"><country-flag rounded country='jo'/> Jordan</vs-option>
    <vs-option value="kz" label="Kazakhstan"><country-flag rounded country='kz'/> Kazakhstan</vs-option>
    <vs-option value="kw" label="Kuwait"><country-flag rounded country='kw'/> Kuwait</vs-option>
    <vs-option value="lv" label="Latvia"><country-flag rounded country='lv'/> Latvia</vs-option>
    <vs-option value="lb" label="Lebanon"><country-flag rounded country='lb'/> Lebanon</vs-option>
    <vs-option value="ly" label="Libya"><country-flag rounded country='ly'/> Libya</vs-option>
    <vs-option value="lt" label="Lithuania<"><country-flag rounded country='lt'/> Lithuania</vs-option>
    <vs-option value="lu" label="Luxembourg"><country-flag rounded country='lu'/> Luxembourg</vs-option>
    <vs-option value="mg" label="Madagascar"><country-flag rounded country='mg'/> Madagascar</vs-option>
    <vs-option value="my" label="Malaysia"><country-flag rounded country='my'/> Malaysia</vs-option>
    <vs-option value="mt" label="Malta"><country-flag rounded country='mt'/> Malta</vs-option>
    <vs-option value="mu" label="Mauritius"><country-flag rounded country='mu'/> Mauritius</vs-option>
    <vs-option value="mx" label="Mexico"><country-flag rounded country='mx'/> Mexico</vs-option>
    <vs-option value="md" label="Moldova"><country-flag rounded country='md'/> Moldova</vs-option>
    <vs-option value="mn" label="Mongolia"><country-flag rounded country='mn'/> Mongolia</vs-option>
    <vs-option value="me" label="Montenegro"><country-flag rounded country='me'/> Montenegro</vs-option>
    <vs-option value="ma" label="Morocco"><country-flag rounded country='ma'/> Morocco</vs-option>
    <vs-option value="mz" label="Mozambique"><country-flag rounded country='mz'/> Mozambique</vs-option>
    <vs-option value="na" label="Namibia"><country-flag rounded country='na'/> Namibia</vs-option>
    <vs-option value="np" label="Nepal"><country-flag rounded country='np'/> Nepal</vs-option>
    <vs-option value="nl" label="Netherlands"><country-flag rounded country='nl'/> Netherlands</vs-option>
    <vs-option value="nc" label="New Caledonia"><country-flag rounded country='nc'/> New Caledonia</vs-option>
    <vs-option value="nz" label="New Zealand"><country-flag rounded country='nz'/> New Zealand</vs-option>
    <vs-option value="ni" label="Nicaragua"><country-flag rounded country='ni'/> Nicaragua</vs-option>
    <vs-option value="ng" label="Nigeria"><country-flag rounded country='ng'/> Nigeria</vs-option>
    <vs-option value="no" label="Norway"><country-flag rounded country='no'/> Norway</vs-option>
    <vs-option value="om" label="Oman"><country-flag rounded country='om'/> Oman</vs-option>
    <vs-option value="pk" label="Pakistan"><country-flag rounded country='pk'/> Pakistan</vs-option>
    <vs-option value="pa" label="Panama"><country-flag rounded country='pa'/> Panama</vs-option>
    <vs-option value="py" label="Paraguay"><country-flag rounded country='py'/> Paraguay</vs-option>
    <vs-option value="pe" label="Peru"><country-flag rounded country='pe'/> Peru</vs-option>
    <vs-option value="ph" label="Philippines"><country-flag rounded country='ph'/> Philippines</vs-option>
    <vs-option value="pl" label="Poland"><country-flag rounded country='pl'/> Poland</vs-option>
    <vs-option value="pt" label="Portugal"><country-flag rounded country='pt'/> Portugal</vs-option>
    <vs-option value="ro" label="Romania"><country-flag rounded country='ro'/> Romania</vs-option>
    <vs-option value="ru" label="Russia"><country-flag rounded country='ru'/> Russia</vs-option>
    <vs-option value="sa" label="Saudi Arabia"><country-flag rounded country='sa'/> Saudi Arabia</vs-option>
    <vs-option value="sn" label="Senegal"><country-flag rounded country='sn'/> Senegal</vs-option>
    <vs-option value="rs" label="Serbia"><country-flag rounded country='rs'/> Serbia</vs-option>
    <vs-option value="sg" label="Singapore"><country-flag rounded country='sg'/> Singapore</vs-option>
    <vs-option value="sk" label="Slovakia"><country-flag rounded country='sk'/> Slovakia</vs-option>
    <vs-option value="si" label="Slovenia"><country-flag rounded country='si'/> Slovenia</vs-option>
    <vs-option value="za" label="South Africa"><country-flag rounded country='za'/> South Africa</vs-option>
    <vs-option value="kr" label="Spain"><country-flag rounded country='kr'/> South Korea</vs-option>
    <vs-option value="es" label="Spain"><country-flag rounded country='es'/> Spain</vs-option>
    <vs-option value="lk" label="Sri Lanka"><country-flag rounded country='lk'/> Sri Lanka</vs-option>
    <vs-option value="se" label="Sweden"><country-flag rounded country='se'/> Sweden</vs-option>
    <vs-option value="ch" label="Switzerland"><country-flag rounded country='ch'/> Switzerland</vs-option>
    <vs-option value="tw" label="Taiwan"><country-flag rounded country='tw'/> Taiwan</vs-option>
    <vs-option value="th" label="Thailand"><country-flag rounded country='th'/> Thailand</vs-option>
    <vs-option value="tt" label="Trinidad and Tobago"><country-flag rounded country='tt'/> Trinidad and Tobago</vs-option>
    <vs-option value="tn" label="Tunisia"><country-flag rounded country='tn'/> Tunisia</vs-option>
    <vs-option value="tr" label="Turkey"><country-flag rounded country='tr'/> Turkey</vs-option>
    <vs-option value="ua" label="Ukraine"><country-flag rounded country='ua'/> Ukraine</vs-option>
    <vs-option value="ae" label="United Arab Emirates"><country-flag rounded country='ae'/> United Arab Emirates</vs-option>
    <vs-option value="gb" label="United Kingdom"><country-flag rounded country='gb'/> United Kingdom</vs-option>
    <vs-option value="us" label="United States"><country-flag rounded country='us'/> United States</vs-option>
    <vs-option value="uy" label="Uruguay"><country-flag rounded country='uy'/> Uruguay</vs-option>
    <vs-option value="ve" label="Venezuela"><country-flag rounded country='ve'/> Venezuela</vs-option>
    <vs-option value="vn" label=" Viet Nam"><country-flag rounded country='vn'/> Viet Nam</vs-option>
    <vs-option value="zm" label="Zambia"><country-flag rounded country='zm'/> Zambia</vs-option>
    <vs-option value="zw" label="Zimbabwe"><country-flag rounded country='zw'/> Zimbabwe</vs-option>
  </vs-select>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import {getNavigatorLanguage} from "@/helpers/helper"

export default {
  name: "CountrySelect",
  components:{
    CountryFlag
  },
  data(){
    return {
      country: '',
      error: false,
    }
  },
  watch: {
    country(value){
      this.error = !value
      this.$emit('country-selected', value)
    }
  },
  created() {
    const navigatorLanguage = getNavigatorLanguage().toLowerCase()

    if (navigatorLanguage === 'en'){
      this.country = 'us'
    } else {
      this.country = navigatorLanguage;
    }

  }
}
</script>

<style scoped lang="scss">
.vs-select__options {
  .flag{
    margin-right: 0.1px;
  }
}
</style>