import axios from "axios";
import {getCookie} from "../cookie/cookie";

export function createSearch(projectId, primaryWord, volumeMin, country, enriched) {
    let burl = process.env.VUE_APP_API;
    console.log(burl);
    return axios({
        method: 'post',
        url: burl + '/api/project/search/add_search',
        data : {projectId, primaryWord, volumeMin, country, enriched},
        headers : {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + getCookie("token")
        }
    })
}


export function createUrlBasedSearch(projectId, url, volumeMin, country, enriched) {
    console.log("REQUEST",projectId, url, volumeMin, country, enriched);
    let burl = process.env.VUE_APP_API;
    console.log(burl);
    return axios({
        method: 'POST',
        url: burl + '/api/project/search/add_url_based_search',
        data : {projectId, url,volumeMin, country},
        headers : {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + getCookie("token")
        }
    })
}
